import Splide from '@splidejs/splide'

import '@splidejs/splide/css/core'

function ready(callbackFunc) {
    if (document.readyState !== 'loading') {
        // Document is already ready, call the callback directly
        callbackFunc()
    } else if (document.addEventListener) {
        // All modern browsers to register DOMContentLoaded
        document.addEventListener('DOMContentLoaded', callbackFunc)
    } else {
        // Old IE browsers
        document.attachEvent('onreadystatechange', function () {
            if (document.readyState === 'complete') {
                callbackFunc()
            }
        })
    }
}

ready(function () {
    const carousel = document.querySelectorAll('.splide')

    if (carousel) {
        carousel.forEach((element) => {
            const gap = element.getAttribute('data-gap') || '2rem'
            const arrows = element.getAttribute('data-arrows') || true

            const splide = new Splide(element, {
                arrows: false,
                perPage: 3,
                perMove: 1,
                gap,
                breakpoints: {
                    1024: {
                        perPage: 2,
                    },
                    699: {
                        perPage: 1,
                    },
                },
            })

            if (arrows) {
                const btnPrev = element.parentNode.querySelector('.caroussel-button-prev')
                const btnNext = element.parentNode.querySelector('.caroussel-button-next')

                if (btnPrev && btnNext) {
                    splide.on('mounted', function () {
                        btnPrev.disabled = splide.index === 0
                    })

                    btnNext.addEventListener('click', () => {
                        splide.go('+1')
                    })

                    btnPrev.addEventListener('click', () => {
                        splide.go('-1')
                    })

                    splide.on('move', function (newIndex) {
                        btnPrev.disabled = newIndex === 0
                        btnNext.disabled = newIndex >= splide.length - splide.options.perPage
                    })

                    splide.on('resized', function () {
                        if (splide.length <= splide.options.perPage) {
                            splide.options.drag = false
                            btnPrev.style.display = 'none'
                            btnNext.style.display = 'none'
                        } else {
                            splide.options.drag = true
                            btnPrev.style.display = 'block'
                            btnNext.style.display = 'block'
                        }
                    })
                }
            }

            splide.mount()
        })
    }
})
